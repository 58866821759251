import React from 'react'
import styled from '@emotion/styled'
import { color_colors_ocean, color_shades_dark, color_variants_ocean_light_2 } from '../../../constants/colors'
import TextField from '../../../components/forms/TextField'
import MenuContainer from '../../../hooks/MenuContainer'
import ContentCollapsingSection from '../../../components/structure/ContentCollapsingSection'
import CheckboxField from '../../../components/forms/CheckboxField'
import CollapsableItem from './CollapsableItem'
import { MenuItemOptionGroup } from '../../../models/nlp/menu/MenuItemOptionGroup'
import ContentCard, { NoMarginContentCard } from '../../../components/structure/ContentCard'
import { getExtraOptionGroupText } from './MenuItemEditor'
import { css } from '@emotion/css'
import PersistentPropertyTitle from './PersistentPropertyTitle'
import SynonymsEditor from '../../interactionModel/components/SynonymsEditor'

const listImage = require("../../../content/images/menu-editor/list.svg");
const inactiveListImage = require("../../../content/images/menu-editor/inactive-list.svg")
const settingsIcon = require('../../../content/images/tab-icons/settings-dark-icon.svg');
const menuItemIcon = require("../../../content/images/menu-editor/restaurant-menu.svg");
const synonymsIcon = require('../../../content/images/synonyms.svg');

export const getOptionGroupName = (optionGroup: MenuItemOptionGroup) => {
    if (optionGroup?.colloquialName?.length) {
        if (optionGroup?.colloquialName.toLowerCase() !== optionGroup?.name.toLowerCase()) {
            return `${optionGroup.name} ("${optionGroup.colloquialName}")`;
        }
    }
    return optionGroup.name;
};

export default function MenuItemSizeEditor() {
    const menuContainer = MenuContainer.useContainer();
    const lastItem = menuContainer.path[menuContainer.path.length - 1];

    const advancedSettings = [
        {
            text: "Don't read this size aloud",
            tooltip: `Don't have the assistant read ${lastItem?.selectedSize?.name || 'this size'} aloud when providing options to the customer.`,
            tooltipStyle: checkboxTooltipStyle,
            isCheckBox: true,
            isChecked: lastItem?.selectedSize?.ignoreForReadout,
            onChange: (e) => {
                lastItem.selectedSize.ignoreForReadout = e.target.checked;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedSize, "ignoreForReadout");
            }
        },
        {
            text: "Number of Required Sizes",
            isCheckBox: false,
            value: lastItem.selectedSize.numberOfRequiredSizes ?? 0,
            onChange: (e) => {
                lastItem.selectedSize.numberOfRequiredSizes = e.target.value;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedSize, "numberOfRequiredSizes");
            }
        }
    ]

    const activeOptionGroups = lastItem.selectedSize?.optionGroups?.filter(optionGroup => !optionGroup.isDisabled) ?? [];
    const inactiveOptionGroups = lastItem.selectedSize?.optionGroups?.filter(optionGroup => optionGroup.isDisabled) ?? [];
    const hasItemLevelPricing = menuContainer.menuItem?.attributes?.data?.price?.length > 0;

    function renderOptionGroups(optionGroups: MenuItemOptionGroup[]) {
        return optionGroups.map((optionGroupObj, index) => {
            return (
                <div key={`optionGroup.${index}`}>
                    <OptionGroupWrapper
                        className={optionGroupObj.referenceId === menuContainer.activeEditId ? "active" : ""}
                        key={optionGroupObj.editId}
                        onClick={() => {
                            const path = {
                                text: optionGroupObj.name,
                                pathLength: menuContainer.path?.length ?? 0,
                                selectedOptionGroup: optionGroupObj,
                                selectedOption: null,
                                selectedSizeGroup: null,
                                selectedSize: null,
                            };
                            menuContainer.setPath([...menuContainer.path, path])
                        }}
                    >
                        <ListItemLeftWrapper>
                            <CollapsableItem
                                text={getOptionGroupName(optionGroupObj)}
                                isDisabled={optionGroupObj.isDisabled}
                                italicText={getExtraOptionGroupText(!!(optionGroupObj.promptIfNoSelections || optionGroupObj.numberOfRequiredOptions), optionGroups)}
                                onEnabledChange={(enabled) => {
                                    optionGroupObj.isDisabled = !enabled;
                                    menuContainer.updateMenuItem(menuContainer.menuItem, optionGroupObj, "isDisabled");
                                }}
                            />
                        </ListItemLeftWrapper>
                    </OptionGroupWrapper>
                </div>
            )
        });
    }

    return (
        <MenuItemOptionWrapper>
            {lastItem?.selectedSize ?
                <>
                    <ContentCollapsingSection
                        title={"Details"}
                        className={firstContentCollapsingSectionStyle}
                        isCollapsedByDefault={false}
                        icon={menuItemIcon}
                    >
                        <StyledContentCard
                            id="sizeDetails" >
                            <TextField
                                name="Colloquial Name"
                                useForm={false}
                                disabled={false}
                                value={lastItem.selectedSize.colloquialName ?? ""}
                                label={
                                    <PersistentPropertyTitle
                                        title="Size Referred to As"
                                        item={lastItem.selectedSize}
                                        propertyName='colloquialName'
                                    />
                                }
                                placeholder={`${`Enter a new name for ${lastItem.selectedSize?.name} or leave blank to use the size's original name`}`}
                                tooltip={`This is the name the assistant will use to refer to ${lastItem?.selectedSize?.name || 'this size'}.`}
                                tooltipStyle={tooltipStyle}
                                onChange={(e) => {
                                    lastItem.selectedSize.colloquialName = e.target.value;
                                    menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedSize, "colloquialName");
                                }}
                                type="string"
                            />

                            <TextField
                                name="Size Price"
                                useForm={false}
                                disabled={hasItemLevelPricing}
                                value={lastItem?.selectedSize?.attributes?.data?.price ?? ""}
                                label={
                                    <PersistentPropertyTitle
                                        title='Size Price'
                                        item={lastItem?.selectedSize?.attributes}
                                        propertyName='data'
                                    />
                                }
                                tooltip={hasItemLevelPricing ? 'The price is currently set at the menu item level. Remove the item level price to configure size dependent pricing.' : 'This should be the cost of the menu item when this size is ordered.'}
                                tooltipStyle={tooltipStyle}
                                placeholder="Price for this size"
                                onChange={(e) => {
                                    if (!lastItem?.selectedSize?.attributes?.data) lastItem.selectedSize.attributes.data = {};
                                    lastItem.selectedSize.attributes.data.price = e.target.value;
                                    menuContainer.updateMenuItem(menuContainer.menuItem, lastItem?.selectedSize?.attributes?.data, "price", { changedObj: lastItem?.selectedSize?.attributes, changedProperty: "data" });
                                }}
                                type="string"
                            />
                        </StyledContentCard>
                    </ContentCollapsingSection>
                    <>
                        {!lastItem.selectedSize?.optionGroups?.length ? <></> :
                            <ContentCollapsingSection
                                title={"Option Groups"}
                                isCollapsedByDefault={false}
                            >
                                <ContentCard
                                    icon={listImage}
                                    title={`Edit Active Option Groups`}
                                >
                                    {activeOptionGroups.length > 0
                                        ? renderOptionGroups(activeOptionGroups)
                                        :
                                        <NoContentAvailable>No active option group available.</NoContentAvailable>
                                    }
                                </ContentCard>
                                <NoMarginContentCard
                                    icon={inactiveListImage}
                                    title={`Edit Inactive Option Groups`}
                                >
                                    {inactiveOptionGroups.length > 0
                                        ? renderOptionGroups(inactiveOptionGroups)
                                        :
                                        <NoContentAvailable>No inactive option group available.</NoContentAvailable>
                                    }
                                </NoMarginContentCard>
                            </ContentCollapsingSection>
                        }
                        <ContentCollapsingSection
                            title={"Synonyms"}
                            isCollapsedByDefault={true}
                            icon={synonymsIcon}
                        >
                            <NoMarginContentCard
                                title="Add Synonyms"
                                subtitle={`Add other names or ways customers might refer to ${lastItem.selectedSize.name ?? "this item"}.`}
                                id="optionSynonyms" >
                                <SynonymsEditor
                                    className={SynonymsClass}
                                    synonyms={lastItem.selectedSize?.additionalSynonyms}
                                    synonymsFieldName={'optionSynonyms'}
                                    disableFields={false}
                                    handleDelete={function (index: number, arrayHelpers: any) {
                                        let newSynonyms = lastItem.selectedSize?.additionalSynonyms?.filter((synonym, i) => i !== index);
                                        if (!newSynonyms?.length)
                                            newSynonyms = [];
                                        lastItem.selectedSize.additionalSynonyms = newSynonyms;
                                        menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedSize, "additionalSynonyms");
                                    }}
                                    handleAddSynonym={function (synonym: string): void {
                                        if (!lastItem?.selectedSize?.additionalSynonyms?.length) lastItem.selectedSize.additionalSynonyms = [];
                                        lastItem.selectedSize.additionalSynonyms.push(synonym);
                                        menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedSize, "additionalSynonyms");
                                    }}
                                    handleBlur={() => { }}
                                    autofocusNewSynonym={false}
                                />
                            </NoMarginContentCard>
                        </ContentCollapsingSection>
                        <ContentCollapsingSection
                            title={"Advanced Settings (Admin Only)"}
                            icon={settingsIcon}
                            isCollapsedByDefault={true}
                            bottom
                            scrollDownOnClick={true}
                        >
                            <NoMarginContentCard
                                title='Edit Size Advanced Settings'
                                subtitle='These settings are for administrators only. Please consult with your technical team before making changes.'
                                id="sizeAdvancedSettings"
                            >
                                {advancedSettings?.map((setting, index) => {
                                    if (setting.isCheckBox) {
                                        return (
                                            <CheckboxField
                                                key={`setting.${index}`}
                                                checked={setting.isChecked ? true : false}
                                                tooltip={setting.tooltip}
                                                tooltipStyle={setting.tooltipStyle}
                                                onChange={setting.onChange}
                                                label={setting.text}
                                            />
                                        )
                                    }
                                    return (
                                        <TextField
                                            key={`setting.${index}`}
                                            useForm={false}
                                            disabled={false}
                                            value={setting.value}
                                            label={setting.text}
                                            placeholder="0"
                                            onChange={setting.onChange}
                                            type={typeof setting.value}
                                        />
                                    )
                                })}
                            </NoMarginContentCard>
                        </ContentCollapsingSection>
                    </>
                </>
                :
                <></>
            }
        </MenuItemOptionWrapper>
    )
};

const firstContentCollapsingSectionStyle = css`
    border-top: none;
`;

const checkboxTooltipStyle = css`
    margin: 0 0 24px 8px;
`;

const tooltipStyle = css`
    margin-left: 8px;
`

const MenuItemOptionWrapper = styled.div`
    height: 100%;
`;

const OptionGroupWrapper = styled.div`
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    margin: 8px 0 8px 0;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &.active {
        border: 1px solid ${color_colors_ocean};
        box-shadow: 0px 0px 4px 4px ${color_variants_ocean_light_2};
    }
`;

const ListItemLeftWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const NoContentAvailable = styled.div`
    padding: 10px;
`;

const StyledContentCard = styled(ContentCard)`
    margin: 8px;
    padding-bottom: 8px;
`;

const SynonymsClass = css`
    margin-top: 16px;
`;